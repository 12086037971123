export default {
    homePage:{
        '开始赚钱计划':'开始赚钱计划',
        '快速进入':'快速进入',
        '市场':'市场',
        '数字货币':'数字货币',
        '涨幅':'涨幅',
        '邀请朋友':'邀请朋友',
        '邀请朋友加入':'邀请朋友加入',
        '开始邀请':'开始邀请',
        '查看':'查看',
        'Ai智能套利':' Ai智能套利 ',
        '200家交易所的智能交易':'200 家交易所的智能交易 ',
        '矿机租赁':' 矿机租赁 ',
        '效率高,回报快':' 效率高,回报快 '
    },

    languagesPage:{
        '语言':'语言',
        '确认':'确认'
    },

    noticeRecord:{
        '通知':'通知',
        '消息':'消息',
        '无数据':'无数据',
    },

    leftPage:{
        '钱包':'钱包',
        '套利':'套利',
        '采矿':'采矿',
        '设置':'设置',
    },

    accountPage:{
        '账户':'账户',
        '立即发送加密货币':'立即发送加密货币',
        '选择一个钱包来发送加密货币':'选择一个钱包来发送加密货币',
        '选择一个钱包':'选择一个钱包',
        '搜索':'搜索',
        '钱包':'钱包',
    },

    walletPage:{
        '钱包':'钱包',
        '可用':'可用',
        '冷冻':'冷冻',
        '接收':'接收',
        '发送':'发送',
        '转换':'转换',
        '存入资金':'存入资金',
        '退出':'退出',
        '最大':'最大',
        '立即发送':'立即发送',
        '从':'从',
        '到':'到',
        '费用':'费用',
        '继续固定':'继续固定',
        '内容1':'请不要将其他类型的资产发送到上述地址。此操作可能会导致您的资产丢失。发送成功后,网络节点需要确认接收相应的资产。所以当您完成转账时,请及时联系在线客服核实到货。',
        '内容2':'请不要将资金转给陌生人',
        '内容3':'你不能直接在两种加密货币之间进行交易。你应该先将一种加密货币兑换成USDT,然后再用USDT兑换成任何其他加密货币。',
        '资金密码':'资金密码',
        '请输入资金密码':'请输入资金密码',
        '未设置资金密码':'未设置资金密码',
        '发送地址不能为空':'发送地址不能为空',
        '金额必须大于0':'金额必须大于0',
    },

    tradingPage:{
        '交易记录':'交易记录',
        '接收':'接收',
        '发送':'发送',
        '转换':'转换',
        '无数据':'无数据',
    },

    arbitragePage:{
        '主持工作':'托管',
        '总套利':'总套利',
        '今日收益':'今日收益',
        '托管订单':'托管订单',
        '介绍':'介绍',
        '人工智能机器人是如何工作的':'人工智能机器人是如何工作的',
        '套利产品':'套利产品',
        'AI智能挖矿':'AI智能挖矿',
        '低买高卖获利':'低买高卖获利',
    },

    trusteeshipDetailPage:{
        '托管详细':'托管详细',
        '主持':'托管中',
        '终止':'已取消',
        '赎回':'已赎回',
        '无数据':'无数据',
    },

    miningPage:{
        '你值得信任的量化投資':'你值得信任的量化投資',
        '立即开户':'立即开户',
        '总流动性矿池金额':'总流动性矿池金额',
        '质押人數':'质押人數',
        '质押':'质押',
        '质押记录':'质押记录',
        '质押收益':'质押收益',
        '输入要质押的USDT数量':'输入要质押的USDT数量',
        '质押总量':'质押总量',
        '最大限度':'最大限度',
        '余额':'余额',
        '钱包余额':'钱包余额',
        '收益率':'收益率',
        '选择质押的期限':'选择质押的期限',
        '没有记录':'没有记录',
        '挖矿收益地址':'挖矿收益地址',
        '数量':'数量',
        '相关问题':'相关问题',
        '什麼是AI量化交易':'什麼是AI量化交易',
        '文本1':'金融量化交易是一种强调教学和统计分析以帮助确定金融资产（例如股票或期权，加密货币）价值的方法。量化交易者使用各种数据—包括历史投资和股票市场数据—来开发交易算法和计算机模型。这些计算机模型生成的信息有助于投资者分析投资机会并制定成功的交易策略。通常，此交易策略将包括有关进场和出场点、交易的预期风险和预期回报的非常具体的信息。财务量化分析的最终目标是使用可量化的统计数据和指标来帮助投资者做出有利可图的投资决策。',
        '为什么要支付手续费':'为什么要支付手续费',
        '文本2':'由于你需要在量化交易中开设一个账户进行质押投资，所以收取的ETH是作为你的开户手续费。',
        'AI量化交易的优势':'AI量化交易的优势',
        '文本3_1':'无需人工干预',
        '文本3_2':'AI量化交易减少认知错误',
        '文本3_3':'AI量化交易策略让成效加倍',
        '文本3_4':'AI量化策略允许回测',
        '文本3_5':'可以实施无限数量的策略',
        '文本3_6':'自动交易解放投资者的时间',
        '文本3_7':'量化交易系统执行与回测的相同性',
        '文本3_8':'系统让您遵循您的计划',
        '在哪里进行AI量化交易':'在哪里进行AI量化交易',
        '文本4':'和Coinbase共同打造的量化交易，所有的程序都于Coinbase钱包中运行，是公开公正的AI量化交易项目。',
        '为什么会有这项目':'为什么会有这项目',
        '文本5':'2022年，和Coinbase正式合作，致力于打造全新AI+金融生态共识圈，和Coinbase共同投资20亿美元的资金，联合推出打造量化交易平台，实现AI+金融完美融合，核心产品是AI分析计算芯片，衍生出的它被搭载于我们最新款6.2.1AI机器人上，他的问世打破并颠覆了多个行业的传统，其中就包括金融交易的传统，使用量化交易平台，让交易变得更加简单。下一步，作为新一代加密资产交易平台还将持续发力，强势推出Web3.0版本。',
        '当前总质押':'当前总质押',
        '当日收益':'当日收益',
        '总收益':'总收益',
    },

    inviteFriendsPage:{
        '邀请朋友':'邀请朋友',
        '邀请朋友获得推荐奖励':'邀请朋友 获得推荐奖励',
        '好友参与即可获得推荐奖励':'好友参与AI套利,租矿机,即可获得推荐奖励',
        '您的邀请成功':'您的邀请成功',
        '全部':'全部',
        '直接':'直接',
        '间接':'间接',
        '更多':'更多',
        '直接收益':'直接收益',
        '间接收益':'间接收益',
        '无数据':'无数据',
        '复制成功':'复制成功',
    },

    coinDetailPage:{
        '交割合约':'交割合约',
        '最低价':'最低价',
        '成交量':'成交量',
        '最高价':'最高价',
        '成交额':'成交额',
        '时间':'时间',
        '更多':'更多',
        '委托订单':'委托订单',
        '最新交易':'最新交易',
        '暂无数据':'暂无数据',
        '记录':'记录',
        '买涨':'买涨',
        '买跌':'买跌',
        '交割':'交割',
        '交割时间':'交割时间',
        '投资金额':'投资金额',
        '金额':'金额',
        '余额':'余额',
        '预期收益':'预期收益',
        '确认下单':'确认下单',
        '请输入投资金额':'请输入投资金额',
        '确认要下单吗':'确认要下单吗？',
        '余额不足':'余额不足',
    },

    contractsOrderPage:{
        '委托订单':'委托订单',
        '等待中':'等待中',
        '已結束':'已結束',
        '无数据':'无数据',
    },

    numKeyboard:{
        '完成':'完成',
        '输入金额不能超过可用金额':'输入金额不能超过可用金额',
        '金额不能超过可用金额':'金额不能超过可用金额',
    },

    settingPage:{
        '设置':'设置',
        '修改资金密码':'修改资金密码',
        '旧密码':'旧密码',
        '请输入旧密码':'请输入旧密码',
        '新密码':'新密码',
        '请输入新密码':'请输入新密码',
        '确认密码':'确认密码',
        '请再次输入新密码':'请再次输入新密码',
        '密码不能为空':'密码不能为空',
        '新密码和确认密码不匹配':'新密码和确认密码不匹配',
        '取消':'取消',
        '确认':'确认',
        '设置资金密码':'设置资金密码',
        '资金密码':'资金密码',
    },

    interfaceJs:{
        '获取失败':'获取失败',
        '登录失败':'登录失败',
        '设置成功':'设置成功',
        '修改成功':'修改成功',
        '添加失败':'添加失败',
        '修改失败':'修改失败',
    },

    "时间":"时间",
    "数量":"数量",
    "状态":"状态",
    "审核中":"审核中",
    "已完成":"已完成",
    "审核未通过":"审核未通过",
    "购买金额":"购买金额",
    "全部":"全部",
    "输入金额":"输入金额",
    "可用余额":"可用余额",
    "大于最大可投":"大于最大可投",
    "购买":"购买",
    "购买成功":"购买成功",
    "请输入购买金额":"请输入购买金额",
    "订单号":"订单号",
    "购买日期":"购买日期",
    "结束日期":"结束日期",
    "预计收益":"预计收益",
    "日收益率":"日收益率",
    "取消托管":"取消托管",
    '接收金额':'接收金额',
    '请填写金额':'请填写金额',
    '上传充值凭证':'上传充值凭证',
    '充值':'充值',
    '请上传图片':'请上传图片',
    '请输入金额':'请输入金额',
    '确认':'确认',
    '产品':'产品',
    '购买数量':'购买数量',
    '购买方向':'购买方向',
    '开仓时间':'开仓时间',
    '平仓时间':'平仓时间',
    '盈亏':'盈亏',
    '取消':'取消',
    '质押数量':'质押数量',
    '期限':'期限',
    '开始时间':'开始时间',
    '结束时间':'结束时间',
    '收益率':'收益率',
    '天':'天',
    '质押金额必须大于0':'质押金额必须大于0',
    '请选择质押产品':'请选择质押产品',

    '选择质押的产品':'选择质押的产品',
    '限额':'限额',
    '周期':'周期',
    '预警收益':'预警收益',
    '数量限制':'数量限制',
    '最少可投':'最少可投',
    '最大可投':'最大可投',
    '立即租用':'立即租用',

    '详情':'详情',
    '收益金额':'收益金额',

    '身份认证':'身份认证',
    '个人身份认证':'个人身份认证',
    '完成认证后可获得的权限':'完成认证后可获得的权限',
    '权限一':'增加提现额度, 24小时限额500BTC',
    '权限二':'增加法币交易额度, 单笔限额1000000USDT',
    '去认证':'去认证',
    '已认证':'已认证',
    '证件类型':'证件类型',
    '身份证':'身份证',
    '姓名':'姓名',
    '证件号码':'证件号码',
    '上传证件正面':'上传证件正面',
    '正面证件照':'正面证件照',
    '上传证件反面':'上传证件反面',
    '反面证件照':'反面证件照',
    '确定':'确定',
    '请输入姓名':'请输入姓名',
    '请输入证件号码':'请输入证件号码',
    '请上传证件':'请上传证件',
    '查看认证信息':'查看认证信息',
}