<template>
  <el-table v-loading.fullscreen.lock="fullscreenLoading" type="primary">
  </el-table>
</template>

<script setup>
import { onMounted,ref } from "vue";
import {initWeb3,getAccount} from "@/utils/web3.js";
import {useRoute, useRouter} from 'vue-router';
import { login } from "@/assets/js/api/interface";

const fullscreenLoading = ref(true)

const router = useRouter();
const route = useRoute();
const account = ref(null);
onMounted(async ()=>{
  await initWeb3();
  account.value = getAccount();
  if (account.value){
    localStorage.setItem("connectionStatus","success")
    console.log("连接成功")
    localStorage.setItem('address',account.value[0])

    const userCode = route.meta.userCode;
    console.log(userCode)
    let inviteCode = ''
    if (userCode!=='no' && userCode!==undefined){
      inviteCode = userCode.split("=")[1];
    }
    console.log("邀请码:"+inviteCode)
    const params = {
      address: account.value[0],
      invite_code: inviteCode
    }
    const data = await login(params);
    localStorage.setItem('token',data.data.token);
    console.log("登录成功")

    router.push("/home")
  }else {
    localStorage.setItem("connectionStatus","fail")
    console.log("连接失败")
    router.push("/download")
  }
})
</script>
