import axios from 'axios';
import {showFailToast, showNotify} from 'vant';

let lang = localStorage.getItem('Language');
const token = localStorage.getItem('token');
if (!lang){
    localStorage.setItem('Language','en-US');
    lang = 'en-US';
}

const apiClient = axios.create({
    // baseURL: 'http://103.197.158.7:90/api', // 替换成你的后端 URL
    baseURL:'https://api.kucoinbyftrz.com/',
    timeout: 10000, //请求超时时间
    headers: {
        'Content-Type': 'application/json',
        'language': lang,
        'Authorization': token,
    },
});

// 请求拦截器
apiClient.interceptors.request.use(
    config => {
        // 在请求发送之前做一些处理，比如添加认证头
        // config.headers['Authorization'] = `Bearer ${token}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 响应拦截器
apiClient.interceptors.response.use(
    response => {
        const { code,msg } = response.data;

          switch (code) {
            case -1:
                showNotify({ type: "danger", message: msg });
                return new Promise(() => {});
            case 401:
                // localStorage.clear();
                showFailToast(msg)
                return new Promise(() => {});
            default:
              return response.data;
          }
    },
    error => {
        // 对响应错误做一些处理
        console.error("系统错误："+error)
        return new Promise(() => {});
    }
);

export default apiClient;